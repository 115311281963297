import { CLEAR_TIME_SLOTS, GET_DATE_SLOTS, GET_TIME_SLOTS, GET_TOTAL_AMOUNT } from "../../constants/treatment/DatetTimeslots"

const initialState = {
    dateSlots: [],
    timeslots: [],
    totalAmount: null
}

export const GetDateTimeSlotsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DATE_SLOTS:
            let tempDate = state?.dateSlots.concat(action.payload?.data?.items)
            return {
                ...state,
                dateSlots: tempDate
            }
        case GET_TIME_SLOTS:
            // let tempTime = state?.timeslots.concat(action.payload?.data?.items)
            let tempTime = action.payload?.data?.items
            return {
                ...state,
                timeslots: tempTime
            }
        case CLEAR_TIME_SLOTS:
            return {
                ...state,
                timeslots: [],
            }

        case GET_TOTAL_AMOUNT:
            return {
                ...state,
                totalAmount: action.payload?.data?.payment
            }
        default:
            return state

    }
}