import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import './fonts/Avenir_Next_Font.ttf';

import App from './App';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, concat } from '@apollo/client';
import { apiConfigUrl } from './config';
import { Provider } from 'react-redux';
import store from './store';
import Cookies from 'js-cookie';

const httpLink = new createUploadLink({ uri: apiConfigUrl });

let authMiddleware = new ApolloLink((operation, forward) => {
  let temapHeader = {}
  console.log(Cookies.get("faces_access_token"), "Cookies.get('faces_access_token')")
  if (Cookies.get("faces_access_token")) {
    temapHeader = { 'encrypted-authorization': Cookies.get('faces_access_token') }
  }
  else {
    temapHeader = { 'authorization': Cookies.get('faces_login_token') }
  }


  const entries = Object.entries(temapHeader);
  const firstEntry = entries[0];
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      [firstEntry[0]]: firstEntry[1]
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);
