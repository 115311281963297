export const apiConfigUrl = process.env.REACT_APP_GRAPHQL_URL;


export const Web_Base_Url = process.env.REACT_APP_WEB_BASE_URL;


export const config = {

    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,

};
